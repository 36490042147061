import { Avatar, Media } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConnectionStatus, OrganizationMembershipRole } from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import ScreenSection from "../lib/ScreenSection";
import { useCurrentMembership } from "../SessionBoundary";
import { GetOrganizationMembershipDocument } from "./GetOrganizationMembership.generated";
import OrganizationMembershipActionsMenu from "./OrganizationMembershipActionsMenu";

export default function OrganizationMembershipScreen() {
  const { t } = useTranslation();
  const { organization } = useCurrentMembership();

  const { organizationMembershipId } = useParams<{
    organizationMembershipId: string;
  }>();
  if (!organizationMembershipId)
    throw new Error("missing organizationMembershipId param");

  const { data, error } = useQuery(GetOrganizationMembershipDocument, {
    fetchPolicy: "cache-and-network",
    variables: { organizationMembershipId },
  });

  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { organizationMembership } = data;
  const { visitor } = organizationMembership;
  // TODO: filter this on the backend
  const activeConnections = visitor.connections.filter(
    (c) => c.status === ConnectionStatus.Active
  );

  // TODO: add team member activity timeline

  return (
    <Screen
      showBack
      showNotifications
      headerAction={
        <OrganizationMembershipActionsMenu
          organizationMembership={organizationMembership}
        />
      }
      title={visitor.fullName}
    >
      <ScreenSection title={t("Profile info")}>
        <DetailsStack>
          <DetailsStackItem label={t("First name")} value={visitor.firstName} />
          <DetailsStackItem label={t("Last name")} value={visitor.lastName} />
          <DetailsStackItem label={t("Email")} value={visitor.email} />
          <DetailsStackItem
            label={t("Role")}
            value={
              {
                [OrganizationMembershipRole.Admin]: t("Team admin"),
                [OrganizationMembershipRole.Member]: t("Team member"),
              }[organizationMembership.role]
            }
          />
        </DetailsStack>
      </ScreenSection>

      <ScreenSection title={t("Resident contacts")}>
        {activeConnections.length === 0 ? (
          <Typography>
            {t("{{firstName}} has no active contacts", {
              firstName: visitor.firstName,
            })}
          </Typography>
        ) : (
          <DetailsStack>
            {activeConnections.map((c) => (
              <Link
                sx={{ textDecoration: "none" }}
                key={c.id}
                to={`/organization/${organization.id}/contacts/${c.inmate.id}`}
              >
                <Media image={<Avatar contact={c.inmate} />}>
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline" }}
                  >
                    {c.inmate.fullName}
                  </Typography>
                </Media>
              </Link>
            ))}
          </DetailsStack>
        )}
      </ScreenSection>
    </Screen>
  );
}
