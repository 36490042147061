import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetApprovedPhoneQueryVariables = Types.Exact<{
  phoneId: Types.Scalars['ID']['input'];
}>;


export type GetApprovedPhoneQuery = { __typename: 'Query', twilioToken: string, facilityApprovedPhone: { __typename: 'FacilityApprovedPhone', name: string, id: Types.Scalars['ID']['input'], number: string, security: Array<Types.SecurityFeature> } };


export const GetApprovedPhoneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApprovedPhone"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"twilioToken"}},{"kind":"Field","name":{"kind":"Name","value":"facilityApprovedPhone"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"number"}},{"kind":"Field","name":{"kind":"Name","value":"security"}}]}}]}}]} as unknown as DocumentNode<GetApprovedPhoneQuery, GetApprovedPhoneQueryVariables>;