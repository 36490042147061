import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BeginTwilioCallDialog from "../TwilioCall/BeginTwilioCallDialog.tsx";
import { MeetingType } from "../api/graphql";
import { ContentSkeleton } from "../lib/closet";
import {
  isMonitored,
  securityFeaturesDescription,
} from "../lib/securityFeaturesUtils.ts";
import useBoolean from "../lib/useBoolean.ts";
import { GetContactsScreenDataQuery } from "./GetContactsScreenData.generated";

type Props = {
  facilityContacts: GetContactsScreenDataQuery | undefined;
};

export default function FacilityContactsList({ facilityContacts }: Props) {
  const { t } = useTranslation();
  const voiceCallModal = useBoolean();
  const data = facilityContacts;
  if (!data?.currentInmate) return <ContentSkeleton />;

  const approvedPhones = data.currentInmate.facility.approvedPhones
    .filter((p) => p.active)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      {approvedPhones.length === 0 && (
        <Box mt={2}>
          <Typography variant="body1" color="text.secondary">
            {t("{{facility}} does not have any facility-wide resources", {
              facility: data.currentInmate.facility.name,
            })}
          </Typography>
        </Box>
      )}
      {approvedPhones.map((approvedPhone) => (
        <Card
          key={approvedPhone.id}
          variant="outlined"
          sx={{ my: 2, px: { xs: 1, sm: 2 }, py: 2 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column" spacing={1}>
              <Typography variant="h2">{approvedPhone.name}</Typography>
              <Typography color="text.secondary" variant="body1">
                {approvedPhone.description}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                {isMonitored(approvedPhone.security) ? (
                  <VisibilityOutlinedIcon color="action" />
                ) : (
                  <LockOutlinedIcon color="action" />
                )}{" "}
                <Box>
                  <Typography color="text.secondary" variant="body1">
                    {securityFeaturesDescription(
                      MeetingType.VoiceCall,
                      approvedPhone.security
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Button onClick={voiceCallModal.enable}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CallOutlinedIcon /> <Box> {t("Call")} </Box>
              </Stack>
            </Button>
            {voiceCallModal.current && (
              <BeginTwilioCallDialog
                approvedPhone={approvedPhone}
                onCancel={voiceCallModal.disable}
              />
            )}
          </Stack>
        </Card>
      ))}
    </Box>
  );
}
