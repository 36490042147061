import { t } from "i18next";
import { FacilityFeature } from "../api/graphql";

const featureTypeToHumanReadable = (Feature: FacilityFeature) => {
  const featureTypes: Record<FacilityFeature, string> = {
    [FacilityFeature.Emessaging]: t("messaging"),
    [FacilityFeature.InPersonVisit]: t("in-person visits"),
    [FacilityFeature.VideoCall]: t("video calls"),
    [FacilityFeature.PstnCall]: t("voice calls over phone network"),
    [FacilityFeature.ConfidentialVideoCall]: t("confidential video calls"),
    [FacilityFeature.ConfidentialInPersonVisit]: t(
      "confidential in-person visits"
    ),
    [FacilityFeature.Webinar]: t("webinars"),
    [FacilityFeature.VoiceCall]: t("voice calls"),
    [FacilityFeature.PhoneCall]: t("outside services"),
  };
  return featureTypes[Feature];
};

export default featureTypeToHumanReadable;
