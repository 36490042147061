import {
  ClientStatus,
  ProducerLabel,
  Role,
} from "@ameelio/connect-call-client";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Box,
  Stack,
  SxProps,
  Theme,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Correspondent, Interval, SecurityFeature } from "../../api/graphql";
import blurBackgroundEnabled from "../../lib/blurBackgroundEnabled";
import isAmeelioTablet from "../../lib/isAmeelioTablet";
import { largeTabletAndUp } from "../../lib/responsiveHelpers";
import { DeviceInfo, useDeviceChanges } from "../../lib/useDevices";
import useMediaPermissions from "../../lib/useMediaPermissions";
import { useCurrentCorrespondent } from "../../SessionBoundary";
import { darkPalette, ltrTheme } from "../../theme";
import { getPeersOrdered, isAttendee, isHost } from "../utils";
import CallBackgroundSelector from "./CallBackgroundSelector";
import CallChat from "./CallChat";
import CallControls from "./CallControls";
import CallPanel, { chatWidth } from "./CallPanel";
import CallParticipants from "./CallParticipants";
import CallTimerWidget from "./CallTimerWidget";
import CallWidget from "./CallWidget";
import { useSegmenterContext } from "./SegmenterProvider";
import SpotlightPlaceholder from "./SpotlightPlaceholder";
import StatusWidget from "./StatusWidget";
import {
  ConnectionInfo,
  LocalWebinarParticipant,
  RemoteWebinarParticipant,
} from "./types/ConnectionInfo";
import CustomBackground from "./types/CustomBackground";
import useResponsiveVideoGridDimensions from "./useResponsiveVideoGridDimensions";
import useSpotlightVideo from "./useSpotlightVideo";
import useUnreadMessageCount from "./useUnreadMessageCount";
import VideoParticipant from "./VideoParticipant";
import VideosGrid from "./VideosGrid";

// Different layouts depending upon participant type / host activity
enum ViewingMode {
  Squares,
  Spotlight,
}

enum Panel {
  None,
  Chat,
  Participants,
  Backgrounds,
}

type Props = {
  facilityName: string;

  // User
  user: {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
  };

  // All correspondents
  correspondents: Pick<
    Correspondent,
    "id" | "firstName" | "lastName" | "fullName"
  >[];

  securityFeatures: SecurityFeature[];

  // Connection information
  connectionInfo: ConnectionInfo;

  interval: Pick<Interval, "startAt" | "endAt">;
  onLeave: () => void;
  onPeerJoined: () => void;

  customBackground?: CustomBackground;
  onSetCustomBackground?: (c: CustomBackground) => void;
};

const backdropStyle: SxProps<Theme> = {
  px: { xs: 1, md: 2 },
  py: { xs: 1, md: 2 },
  height: "100%",
  backgroundColor: darkPalette.background.default,
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: ltrTheme.spacing(2),
};

const canBlurBackground = blurBackgroundEnabled();

function WebinarCallInterface({
  facilityName,
  connectionInfo,
  user,
  correspondents,
  securityFeatures,
  interval,
  onLeave,
  customBackground,
  onSetCustomBackground,
  onPeerJoined,
}: Props) {
  const { t } = useTranslation();
  const correspondentById = Object.fromEntries(
    correspondents.map((c) => [c.id, c])
  );

  const {
    error,
    messages,
    sendMessage,
    remoteAudioMute,
    remoteAudioUnmute,

    // Screensharing
    onScreenshare,

    // Things both have:
    callStatus,
    clientStatus,

    localProducers,
    resumeProducer,
    pauseProducer,

    peers, // Can be many peers
    chatEnabled,

    logClientEvent,

    user: receivedUser,
  } = connectionInfo;

  if (error) throw error;

  // Declares authenticated user as a host or attendee (student)
  const currentUser = useCurrentCorrespondent();
  const currentUserRole =
    receivedUser?.user.role ||
    (currentUser?.__typename === "Visitor"
      ? Role.webinarHost
      : Role.webinarAttendee);
  const selfIsHost = isHost(currentUserRole);
  const selfIsAttendee = isAttendee(currentUserRole);
  const selfAsParticipant: LocalWebinarParticipant = useMemo(
    () => ({
      isLocal: true,
      user: {
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        fullName: currentUser.fullName,
        role: currentUserRole,
      },
    }),
    [currentUser, currentUserRole]
  );

  // Used for responsive layout
  const isLargeTabletAndUp = largeTabletAndUp(measureScreenWidth);

  const [peerHasConnected, setPeerHasConnected] = useState<boolean>(false);
  const [shownPanel, setShownPanel] = useState<Panel>(Panel.None);

  // Local screensharing status
  const [isScreensharing, setIsScreensharing] = useState<boolean>(false);
  const [screensharingTrack, setScreensharingTrack] =
    useState<MediaStreamTrack | null>(null);
  // Determines if a peer is screensharing
  const peerIsScreensharing = Object.values(peers).some(
    (p) =>
      p.consumers.screenshare?.stream &&
      !!p.consumers.screenshare?.stream.getVideoTracks().length
  );

  // Used to, by default, set a custom background for attendees
  useEffect(() => {
    // SC-6218 Blur filter on a 2D canvas isn't currently supported in Safari
    if (selfIsAttendee && onSetCustomBackground && canBlurBackground) {
      onSetCustomBackground(CustomBackground.BLUR);
    }
  }, [selfIsAttendee, onSetCustomBackground]);

  // Track last known set of unjoined peers
  // and trigger onPeerJoined event when a new one appears.
  const knownPeers = useRef<Set<string>>(new Set());
  useEffect(() => {
    Object.keys(peers).forEach((key) => {
      if (!knownPeers.current.has(key)) onPeerJoined();
    });
    knownPeers.current = new Set(Object.keys(peers));
  }, [peers, onPeerJoined]);

  const unreadMessageCount = useUnreadMessageCount(
    messages,
    shownPanel === Panel.Chat
  );

  // Used to show/hide the "Waiting for others to join" modal
  useEffect(() => {
    if (!peerHasConnected) {
      setPeerHasConnected(!!Object.values(peers)[0]);
    }
  }, [peers, peerHasConnected]);

  // Callback invoked when user (local) starts sharing their screen
  const toggleScreenshare = useCallback(async () => {
    // If not already screensharing
    if (onScreenshare && !isScreensharing) {
      const track = await onScreenshare();
      if (track) {
        track.addEventListener("ended", () => {
          setIsScreensharing(false);
          setScreensharingTrack(null);
        });
        setIsScreensharing(true);
        setScreensharingTrack(track);
      }
    } else if (screensharingTrack) {
      screensharingTrack.stop();
      screensharingTrack.dispatchEvent(new Event("ended"));
    }
  }, [onScreenshare, screensharingTrack, isScreensharing]);

  // Sets audio paused state on server
  const toggleAudio = useCallback(() => {
    try {
      if (localProducers.audio?.paused) resumeProducer(ProducerLabel.audio);
      else pauseProducer(ProducerLabel.audio);
    } catch (e) {
      if (e instanceof Error && e.message !== "Not connected") {
        Sentry.captureException(e);
      }
    }
  }, [pauseProducer, resumeProducer, localProducers]);

  // Sets video paused state on server
  const toggleVideo = useCallback(() => {
    try {
      if (localProducers.video?.paused) resumeProducer(ProducerLabel.video);
      else pauseProducer(ProducerLabel.video);
    } catch (e) {
      if (e instanceof Error && e.message !== "Not connected") {
        Sentry.captureException(e);
      }
    }
  }, [pauseProducer, resumeProducer, localProducers]);

  // Callback invoked when the local user toggles their audio control
  const toggleAudioIfAble = useCallback(() => {
    if (
      // any non-student can always toggle their own audio
      !selfIsAttendee ||
      // an unmuted student can mute themselves
      (selfIsAttendee && !localProducers.audio?.paused)
    ) {
      toggleAudio();
    }
  }, [toggleAudio, selfIsAttendee, localProducers]);

  const { status: segmenterStatus } = useSegmenterContext();

  // Used for setting the view layout
  const viewingMode = useMemo(() => {
    if (!selfIsHost) return ViewingMode.Spotlight;
    if (selfIsHost && !isScreensharing && !peerIsScreensharing)
      return ViewingMode.Squares;
    return ViewingMode.Spotlight;
  }, [isScreensharing, peerIsScreensharing, selfIsHost]);

  // attendees see (host) tags on video labels
  const getPeerNameTag = useCallback(
    (peerRole: Role, peerName: string) => {
      const hostTag =
        selfIsAttendee && peerRole === Role.webinarHost ? t("(host)") : "";
      return peerRole === Role.webinarHost
        ? `${peerName} ${hostTag}`.trim()
        : peerName;
    },
    [t, selfIsAttendee]
  );

  // Merges API & CVH data sets with a stable sort
  const sortedPeers: RemoteWebinarParticipant[] = useMemo(
    () =>
      getPeersOrdered(Object.values(peers)).map((p) => {
        const correspondent = correspondents.find(
          (c) => c.id === p.user.id
        ) || {
          firstName: "",
          lastName: "",
          fullName: "",
        };
        return {
          ...p,
          name: correspondent,
          label: getPeerNameTag(p.user.role, correspondent.fullName),
        };
      }),
    [peers, correspondents, getPeerNameTag]
  );

  // Used for determining what to display in the spotlit video tile in Spotlight mode
  const spotlitParticipant = useSpotlightVideo({
    localParticipant: selfAsParticipant,
    remoteParticipants: sortedPeers,
    isScreensharing,
  });

  const gridRows = viewingMode === ViewingMode.Spotlight ? 1 : undefined;
  const { itemsPerPage, gridColumns } =
    useResponsiveVideoGridDimensions(gridRows);

  // Determines if a screenshare stream is in the spotlight
  const isScreensharingSpotlight =
    spotlitParticipant &&
    "connectionState" in spotlitParticipant &&
    spotlitParticipant.consumers.screenshare &&
    !spotlitParticipant.consumers.screenshare.paused;

  // Determines if a video stream is in the spotlight
  const isVideoSpotlight =
    spotlitParticipant &&
    "connectionState" in spotlitParticipant &&
    spotlitParticipant.consumers.video &&
    !spotlitParticipant.consumers.video.paused;

  const onDeviceAdded = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device added: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  const onDeviceRemoved = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device removed: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  // Handle device changes (e.g. unplugging a microphone)
  useDeviceChanges(onDeviceAdded, onDeviceRemoved);

  const { cameraDisabled, microphoneDisabled } = useMediaPermissions();
  useEffect(() => {
    if (clientStatus === ClientStatus.connected) {
      logClientEvent(
        `Device availability (status: ${clientStatus}) - Camera: ${!cameraDisabled} - Microphone: ${!microphoneDisabled}`
      );
    }
  }, [cameraDisabled, microphoneDisabled, clientStatus, logClientEvent]);

  return (
    <Box
      sx={{
        height: 1,
        width:
          shownPanel !== Panel.None && isLargeTabletAndUp
            ? `calc(100% - ${chatWidth})`
            : "100%",
      }}
    >
      <Box sx={backdropStyle}>
        <VideosGrid
          remoteParticipants={sortedPeers}
          localParticipant={selfAsParticipant}
          participantsPerPage={itemsPerPage}
          gridColumns={gridColumns}
          gridRows={gridRows}
          onRemoteAudioMute={
            selfIsHost && remoteAudioMute ? remoteAudioMute : undefined
          }
          onRemoteAudioUnmute={
            selfIsHost && remoteAudioUnmute ? remoteAudioUnmute : undefined
          }
          callStatus={callStatus}
          clientStatus={clientStatus}
          localProducers={localProducers}
          pagerStyleOverrides={
            viewingMode === ViewingMode.Spotlight
              ? {
                  minHeight: "100px",
                  maxHeight: "150px",
                  px: 4,
                }
              : undefined
          }
        />
        {viewingMode === ViewingMode.Spotlight &&
          (isScreensharing ? (
            <VideoParticipant
              user={user}
              origin="local"
              audioMuted={!!localProducers.audio?.paused}
              videoPaused={localProducers.screenshare?.paused}
              audioStream={localProducers.audio?.stream}
              stream={localProducers.screenshare?.stream}
              callStatus={callStatus}
              clientStatus={clientStatus}
              userNameTag={t("Your screen")}
            />
          ) : isScreensharingSpotlight ? (
            <VideoParticipant
              user={spotlitParticipant.name}
              origin="remote"
              audioMuted={!!spotlitParticipant.consumers.audio?.paused}
              videoPaused={spotlitParticipant.consumers.screenshare?.paused}
              audioStream={spotlitParticipant.consumers.audio?.stream}
              stream={spotlitParticipant.consumers.screenshare?.stream}
              callStatus={callStatus}
              clientStatus={clientStatus}
              userNameTag={t("{{fullName}} screen", {
                fullName: spotlitParticipant.name.fullName,
              })}
            />
          ) : isVideoSpotlight ? (
            <VideoParticipant
              user={spotlitParticipant.name}
              origin="remote"
              audioMuted={!!spotlitParticipant.consumers.audio?.paused}
              videoPaused={spotlitParticipant.consumers.video?.paused}
              audioStream={spotlitParticipant.consumers.audio?.stream}
              stream={spotlitParticipant.consumers.video?.stream}
              callStatus={callStatus}
              clientStatus={clientStatus}
              userNameTag={getPeerNameTag(
                spotlitParticipant.user.role,
                spotlitParticipant.name.fullName
              )}
              canControlAudio={false} // If students can ever become spotlit, change this explicit false!
              fit="cover"
            />
          ) : (
            <SpotlightPlaceholder />
          ))}

        {clientStatus === "disconnected" ? (
          <StatusWidget
            message={t(
              "You are disconnected from the call. Please check your network connection."
            )}
          />
        ) : !peerHasConnected ? (
          <StatusWidget message={t("Waiting for others to join")} />
        ) : null}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "space-between", sm: "flex-start" },
            width: 1,
            zIndex: "modal",
            pb: { xs: 1, md: 0 },
          }}
        >
          <Box
            sx={{
              flex: "1 1 0px",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: { xs: "center", sm: "flex-start" },
              flexDirection: "column",
              mb: { xs: 1, sm: 0 },
              mr: 2,
            }}
          >
            <Stack direction="row" spacing={2}>
              <CallTimerWidget
                startAt={interval.startAt}
                endAt={interval.endAt}
              />
              <CallWidget
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setShownPanel(
                    shownPanel === Panel.Participants
                      ? Panel.None
                      : Panel.Participants
                  )
                }
                display="flex"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <GroupsIcon />
                  <Box>{Object.values(peers).length + 1}</Box>
                </Stack>
              </CallWidget>
            </Stack>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <CallControls
              muted={!!localProducers.audio?.paused}
              hidden={!!localProducers.video?.paused}
              alerts={false}
              isScreensharing={isScreensharing}
              micDisabled={selfIsAttendee && !!localProducers.audio?.paused}
              micDisabledHelperText={t("Only hosts can unmute you")}
              onToggleAudio={toggleAudioIfAble}
              onToggleVideo={selfIsHost ? toggleVideo : undefined}
              backgroundSelectionDisabled={
                clientStatus !== ClientStatus.connected
              }
              onToggleBackgroundSelection={
                segmenterStatus === "failed" ||
                !onSetCustomBackground ||
                isAmeelioTablet()
                  ? undefined
                  : () => {
                      setShownPanel(
                        shownPanel === Panel.Backgrounds
                          ? Panel.None
                          : Panel.Backgrounds
                      );
                    }
              }
              onToggleChat={
                chatEnabled
                  ? () =>
                      setShownPanel(
                        shownPanel === Panel.Chat ? Panel.None : Panel.Chat
                      )
                  : undefined
              }
              screenshareDisabled={
                peerIsScreensharing || clientStatus !== ClientStatus.connected
              }
              onToggleScreenshare={selfIsHost ? toggleScreenshare : undefined}
              messageCount={unreadMessageCount}
              onLeave={onLeave}
              leaveCallLabel={t("Leave call")}
              styleOverrides={{
                maxWidth: "100%",
                whiteSpace: "normal",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            />
          </Box>
        </Box>
      </Box>
      {shownPanel !== Panel.None && (
        <CallPanel>
          {shownPanel === Panel.Participants && (
            <CallParticipants
              isHost={selfIsHost}
              userId={user.id}
              peers={Object.values(peers)}
              toggleAudio={toggleAudio}
              audioPaused={!!localProducers.audio?.paused}
              remoteAudioMute={remoteAudioMute}
              remoteAudioUnmute={remoteAudioUnmute}
              meetingUsers={correspondentById}
              onClose={() => setShownPanel(Panel.None)}
            />
          )}
          {shownPanel === Panel.Chat && (
            <CallChat
              facilityName={facilityName}
              securityFeatures={securityFeatures}
              // 2023.04.22 - For webinars, only received staff alerts should play a sound
              muteSounds
              currentUserId={user.id}
              correspondents={correspondents}
              messages={messages}
              onSend={
                callStatus === "live" && clientStatus === ClientStatus.connected
                  ? async (message) => {
                      await sendMessage(message).catch((e) =>
                        Sentry.captureException(e)
                      );
                    }
                  : undefined
              }
              onClose={() => setShownPanel(Panel.None)}
            />
          )}
          {shownPanel === Panel.Backgrounds && onSetCustomBackground && (
            <CallBackgroundSelector
              value={customBackground || CustomBackground.NONE}
              onChange={onSetCustomBackground}
              onClose={() => setShownPanel(Panel.None)}
              canClearCustomBackground={!selfIsAttendee}
              canBlurBackground={canBlurBackground} // SC-6218 Blur filter on a 2D canvas isn't currently supported in Safari
            />
          )}
        </CallPanel>
      )}
    </Box>
  );
}

export default WebinarCallInterface;
