import { useQuery } from "@apollo/client";
import { Suspense, useCallback, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DialingScreen, { BlankDialingScreen } from "../Call/DialingScreen";
import TwilioLiveCall from "../Call/LiveCall/TwilioLiveCall";
import getFrom from "../lib/getFrom";
import useApolloErrorHandler from "../lib/handleApolloError";
import StreamTrackerProvider from "../lib/StreamTrackerProvider";
import { GetApprovedPhoneDocument } from "./GetApprovedPhone.generated";

type VoiceCallPhase =
  | "dialing"
  | "call"
  | "ended"
  | "terminated"
  | "missed"
  | "missed-closed"
  | "connectedElsewhere";

export default function TwilioCallScreen() {
  const navigate = useNavigate();
  const { phoneId } = useParams<{ phoneId: string }>();
  const location = useLocation();
  const from = getFrom(location) || "/";
  const [phase, setPhase] = useState<VoiceCallPhase>("call");
  const [muted, setMuted] = useState(false);
  const [alerts, setAlerts] = useState(true);

  const handleApolloError = useApolloErrorHandler();

  if (!phoneId) throw new Error("Missing id");

  const onLeave = useCallback(() => navigate(from), [from, navigate]);

  const { data, loading, error } = useQuery(GetApprovedPhoneDocument, {
    fetchPolicy: "no-cache", // do not cache access token
    variables: {
      phoneId,
    },
    onError: (e) => handleApolloError(e),
  });

  if (error) throw error;

  if (!data || loading) return <BlankDialingScreen />;

  const jwt = data.twilioToken;
  const { name: contactName, number, security } = data.facilityApprovedPhone;

  let children: React.ReactNode;
  if (phase === "dialing") {
    children = (
      <DialingScreen
        muted={muted}
        alerts={alerts}
        onLeave={onLeave}
        onToggleAlerts={() => setAlerts((a) => !a)}
        onToggleAudio={() => setMuted((m) => !m)}
        meeting={{
          security,
          correspondents: [
            {
              __typename: "Visitor",
              id: "twilio-ad-hoc-correspondent",
              firstName: number,
              lastName: "",
              fullName: number,
            },
          ],
          connections: [],
        }}
        missed={false}
      />
    );
  } else if (phase === "call") {
    children = (
      <Suspense
        fallback={
          <DialingScreen
            muted={muted}
            alerts={alerts}
            onLeave={onLeave}
            onToggleAlerts={() => setAlerts((a) => !a)}
            onToggleAudio={() => setMuted((m) => !m)}
            meeting={{
              security,
              correspondents: [
                {
                  __typename: "Visitor",
                  id: "twilio-ad-hoc-correspondent",
                  firstName: number,
                  lastName: "",
                  fullName: number,
                },
              ],
              connections: [],
            }}
            missed={false}
          />
        }
      >
        <TwilioLiveCall
          call={{
            phoneId,
            jwt,
            number,
            contactName,
            security,
          }}
          onLeave={onLeave}
          beginMuted={muted}
          alerts={alerts}
          onToggleAlerts={() => setAlerts((a) => !a)}
          onPeerJoined={() => undefined}
          onTerminated={() => setPhase("terminated")}
          onEnded={onLeave}
        />
      </Suspense>
    );
  } else {
    return <Navigate to={from} />;
  }

  return <StreamTrackerProvider>{children}</StreamTrackerProvider>;
}
