import { Tooltip } from "@ameelio/ui";
import { Lock } from "@mui/icons-material";
import { MeetingType, SecurityFeature } from "../../api/graphql";
import { securityFeaturesDescription } from "../../lib/securityFeaturesUtils.ts";
import CallWidget from "./CallWidget";

export default function CallConfidentialWidget({
  securityFeatures,
}: {
  securityFeatures: SecurityFeature[];
}) {
  return (
    <CallWidget>
      <Tooltip
        title={securityFeaturesDescription(
          MeetingType.VideoCall,
          securityFeatures
        )}
      >
        <Lock sx={{ height: 16 }} />
      </Tooltip>
    </CallWidget>
  );
}
