import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentCorrespondent } from "../SessionBoundary";

export default function MessagingUnavailableAlert({
  professionalConnection,
  pstnConnection,
}: {
  professionalConnection: boolean;
  pstnConnection: boolean;
}) {
  const { t } = useTranslation();
  const user = useCurrentCorrespondent();

  return (
    <Alert severity="warning">
      <AlertTitle>{t("Messaging is not available")}</AlertTitle>
      {user.__typename === "Visitor"
        ? t("The facility this person is in does not allow messaging.")
        : professionalConnection
          ? t(
              "Your facility does not allow messaging with professional contacts."
            )
          : pstnConnection
            ? t("Messages may not be sent to phone number contacts.")
            : t(
                "Your facility does not allow messaging with non-professional contacts."
              )}
    </Alert>
  );
}
