import { Button, Dialog, DialogActions, DialogContent } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FacilityApprovedPhone, MeetingType } from "../api/graphql";
import {
  securityDisclaimer,
  securityFeaturesDescription,
} from "../lib/securityFeaturesUtils.ts";

type Props = {
  approvedPhone: Pick<FacilityApprovedPhone, "id" | "security">;
  onCancel: () => void;
};

export default function BeginTwilioCallDialog({
  onCancel,
  approvedPhone,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog
      title={t("Review the following before making your phone call")}
      onClose={onCancel}
    >
      <DialogContent sx={{ marginTop: 2 }}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="overline">{t("Monitoring")}</Typography>
            <Typography variant="body1">
              {securityFeaturesDescription(
                MeetingType.VoiceCall,
                approvedPhone.security
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="overline">{t("Privacy Notice")}</Typography>
            <Typography variant="body1">
              {securityDisclaimer(
                MeetingType.VoiceCall,
                approvedPhone.security
              )}
            </Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button autoFocus onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/phone-call/${approvedPhone.id}`, {
              state: { from: location.pathname },
            });
          }}
        >
          {t("Agree & Call")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
