import { Dialog, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useApolloErrorHandler from "../lib/handleApolloError";
import { PrimaryButton, SecondaryButton } from "../lib/ModalButtons";
import {
  ConnectionRelationship,
  HiddenRelationship,
  PersonalRelationship,
  ProfessionalRelationship,
} from "../lib/Relationship";
import RelationshipInput from "../lib/RelationshipInput";
import { hasMaxLength, isRequiredString, mergeRules } from "../lib/validate";
import { UpdateConnectionRelationshipDocument } from "./UpdateConnectionRelationship.generated";

type Props = {
  relationship: string;
  connectionId: string;
  onClose: () => void;
};

type FormData = {
  relationship: ConnectionRelationship;
  relationshipDetails: string;
};

export default function EditConnectionRelationshipDialog({
  relationship,
  connectionId,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const isStandardRelationship =
    relationship &&
    (Object.values(PersonalRelationship).includes(
      relationship as PersonalRelationship
    ) ||
      Object.values(ProfessionalRelationship).includes(
        relationship as ProfessionalRelationship
      ) ||
      Object.values(HiddenRelationship).includes(
        relationship as HiddenRelationship
      ));
  const isOtherRelationship = relationship && !isStandardRelationship;

  const defaultValues: FormData = {
    relationship:
      relationship && isStandardRelationship
        ? relationship
        : relationship
          ? PersonalRelationship.Other
          : "",
    relationshipDetails: isOtherRelationship ? relationship : "",
  };

  const { handleSubmit, formState, control, watch } = useForm<FormData>({
    mode: "all",
    defaultValues,
  });

  const [updateConnection, { loading }] = useMutation(
    UpdateConnectionRelationshipDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: onClose,
    }
  );
  const selectedRelationship = watch("relationship") || "";

  const onSubmit = async (formData: FormData) => {
    const { relationship: relationshipValue, relationshipDetails } = formData;
    await updateConnection({
      variables: {
        input: {
          connectionId,
          relationship:
            relationshipValue === PersonalRelationship.Other
              ? relationshipDetails.trim()
              : relationshipValue,
        },
      },
    });
  };

  return (
    <Dialog title={t("Edit relationship")} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" color="text.primary">
          {t(
            "By changing the relationship with your contact, your request will need further review."
          )}
        </Typography>
        <Stack marginTop={3} spacing={3}>
          <RelationshipInput control={control} name="relationship" />
          {selectedRelationship === PersonalRelationship.Other && (
            <TextInput
              control={control}
              name="relationshipDetails"
              label={t("Relationship type")}
              rules={mergeRules(
                isRequiredString(t("Please enter your relationship type.")),
                hasMaxLength(
                  30,
                  t(
                    "Relationship type can't be longer than {{number}} characters.",
                    { number: 30 }
                  )
                )
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{t("Cancel")}</SecondaryButton>
        <PrimaryButton
          disabled={loading || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {t("Confirm")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
