import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  ConnectionFeature,
  ConnectionStatus,
  CorrespondentFeature,
  FacilityService,
} from "../api/graphql";
import CallEnded from "../Call/CallEnded.svg";
import EmptyScreen from "../EmptyScreen";
import UserAlerts from "../Events/UserAlerts";
import { unaccompaniedVisitAllowed } from "../Events/utils";
import buildPageTitle from "../lib/buildPageTitle";
import { ContentSkeleton } from "../lib/closet";
import Link from "../lib/Link";
import Screen from "../lib/Screen";
import useFeaturePermitted from "../lib/useFeaturePermitted";
import useHasScheduling from "../lib/useHasScheduling";
import useVoiceCallPermission from "../lib/useVoiceCallPermission";
import { useCurrentCorrespondent } from "../SessionBoundary";
import ConnectionCard from "./ConnectionCard";
import FacilityContactsList from "./FacilityContactsList";
import { GetConnectionsDocument } from "./GetConnections.generated";
import { GetContactsScreenDataDocument } from "./GetContactsScreenData.generated";

export default function ContactsScreen() {
  const { t } = useTranslation();
  const headerTitle = t("Contacts");
  const pageTitle = buildPageTitle(headerTitle);
  const user = useCurrentCorrespondent();
  const { data, error } = useQuery(GetConnectionsDocument, {
    fetchPolicy: "cache-and-network",
  });

  const { data: facilityContactsData, error: facilityContactsError } = useQuery(
    GetContactsScreenDataDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { nextVoiceCallSlot, userMayMakeVoiceCall } = useVoiceCallPermission(
    user,
    facilityContactsData?.currentInmate?.group
  );

  const mayInitiateVoiceCall = useFeaturePermitted(
    CorrespondentFeature.MakeVoiceCalls
  );

  const mayInitiatePhoneCall = useFeaturePermitted(
    CorrespondentFeature.MakePhoneCalls
  );

  const mayScheduleEvents = useFeaturePermitted(
    CorrespondentFeature.ScheduleMeetings
  );

  const schedulingIsSupported = useHasScheduling();

  const apiError = error || facilityContactsError;
  if (apiError) throw apiError;

  if (!data?.currentCorrespondent) return <ContentSkeleton />;

  // Get all connections except inactive
  const connections = data.currentCorrespondent.connections.filter(
    (c) => c.status !== ConnectionStatus.Inactive
  );

  connections.sort((a, b) =>
    `${a.contact.fullName}`.localeCompare(`${b.contact.fullName}`)
  );

  const action =
    user.__typename === "Visitor" ? (
      <Link
        button
        variant="contained"
        state={{
          fromPath: "/contacts",
          fromName: t("Contacts"),
        }}
        to="/contacts/add"
        sx={{ flexGrow: 1 }}
      >
        {t("Request contact")}
      </Link>
    ) : null;

  return (
    <Screen showNotifications title={headerTitle} headerAction={action}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <UserAlerts />
      {mayInitiatePhoneCall && (
        <Box mb={2}>
          <Typography variant="h2">{t("Your contacts")}</Typography>
        </Box>
      )}
      {connections.length ? (
        <Stack spacing={2}>
          {connections.map((connection) => (
            <ConnectionCard
              key={connection.id}
              mayMakeVoiceCall={userMayMakeVoiceCall}
              nextVoiceCallSlot={nextVoiceCallSlot}
              showVoiceCall={
                mayInitiateVoiceCall &&
                connection.status === ConnectionStatus.Active
              }
              showMessage={
                connection.features.includes(ConnectionFeature.SendMessages) &&
                !connection.activeRestrictions.find(
                  (r) => r.service === FacilityService.Emessaging
                ) &&
                connection.status === ConnectionStatus.Active
              }
              showScheduleEvent={
                mayScheduleEvents &&
                schedulingIsSupported &&
                connection.status === ConnectionStatus.Active &&
                unaccompaniedVisitAllowed(user, connection.id)
              }
              connection={connection}
            />
          ))}
        </Stack>
      ) : (
        <EmptyScreen
          message={t(
            "You don’t have any contacts yet. This is where you’ll be able to see everyone you are connected to."
          )}
          illustration={CallEnded}
          illustrationWidth="318"
        />
      )}

      {mayInitiatePhoneCall && (
        <Box mt={8}>
          <Typography variant="h2">{t("Outside services")}</Typography>
          <FacilityContactsList facilityContacts={facilityContactsData} />
        </Box>
      )}
    </Screen>
  );
}
