import { Button } from "@ameelio/ui";
import { useTranslation } from "react-i18next";

type Props = {
  disabled: boolean;
  disabledReason: string;
  onClick: () => void;
};

export default function ResendInvitationLinkButton({
  disabled,
  disabledReason,
  onClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabled}
      disabledReason={disabledReason}
      variant="outlined"
      onClick={onClick}
    >
      {t("Resend email")}
    </Button>
  );
}
