import {
  Button,
  CheckboxInputBase,
  SelectInputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextInput,
  TextInputBase,
  useSnackbarContext,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Button as MUIButton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useApolloErrorHandler from "../../lib/handleApolloError";
import Screen from "../../lib/Screen";
import { isRequired, isValidUUID, mergeRules } from "../../lib/validate";
import { GetCallInfoDocument } from "./GetCallInfo.generated";

type FormData = {
  meetingId: string;
};

type LogEntry = {
  timestamp: string;
  level: string;
  message: string;
  details?: string;
  socketId?: string;
  eventName?: string;
  userType?: string;
  relativeTimestamp?: string;
};

enum UserFilterTypes {
  DOC = "doc",
  USER = "user",
  INMATE = "inmate",
  ALL = "all",
}

const downloadFile = (url: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.target = "_blank";
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const readFile = async (url: string) => {
  const response = await fetch(url);
  const text = await response.text();
  return text;
};

const parseLog = (log: string): LogEntry[] => {
  const logEntries: LogEntry[] = [];
  const logLines = log.split("\n");
  const logRegex =
    // eslint-disable-next-line no-control-regex
    /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z) \[\u001b\[\d{2}m(\w+)\u001b\[\d{2}m\] (\[.*?\])?(.*)$/;

  const socketIdToUserTypeMap: Record<string, string> = {};

  logLines.forEach((line) => {
    const match = logRegex.exec(line);
    if (match) {
      const [, timestamp, level, socketId, message] = match;
      const eventNameMatch = message?.match(/[><]\[(\w+)\]/);
      const eventName = eventNameMatch ? eventNameMatch[1] : undefined;
      const cleanSocketId = socketId ? socketId.slice(1, -1) : undefined;
      const userTypeMatch = message?.match(
        /(?:type|userType)=\s*(doc|user|inmate)/
      );
      const userType = userTypeMatch ? userTypeMatch[1] : undefined;
      // If we find the userType in the message, we store it in the map with its socketId
      if (cleanSocketId && userType && !socketIdToUserTypeMap[cleanSocketId]) {
        socketIdToUserTypeMap[cleanSocketId] = userType;
      }

      logEntries.push({
        timestamp,
        level,
        message,
        socketId: cleanSocketId,
        eventName,
        userType,
      });
    }
  });

  const firstTimestamp =
    logEntries.find((r) => Boolean(r.timestamp))?.timestamp || null;
  const firstDate = firstTimestamp ? new Date(firstTimestamp) : null;
  return logEntries.map((entry) => {
    const entryDate = new Date(entry.timestamp);
    const relativeTimestamp = firstDate
      ? new Date(entryDate.getTime() - firstDate.getTime())
          .toISOString()
          .substr(11, 12)
      : "00:00:00.000";
    return {
      ...entry,
      userType: entry.socketId
        ? socketIdToUserTypeMap[entry.socketId]
        : entry.userType || "",
      relativeTimestamp,
    };
  });
};

const sanitizeIndexKey = (key: string) =>
  key.replace(/[^a-zA-Z0-9]/g, "_").substring(0, 40);

const inferRowColor = (entry: LogEntry) => {
  if (entry.level === "error") {
    return "error.main";
  }
  if (entry.level === "warn") {
    return "orange";
  }
  return "text.primary";
};

export default function DebugCallsScreen() {
  const handleApolloError = useApolloErrorHandler();
  const snackbarContext = useSnackbarContext();
  const [logData, setLogData] = useState<string | null>(null);
  const [parsedLogData, setParsedLogData] = useState<LogEntry[]>([]);
  const [filteredLogData, setFilteredLogData] = useState<LogEntry[]>([]);
  const [selectedMeetingId, setSelectedMeetingId] = useState<string>("");
  const [showRelativeTimestamp, setShowRelativeTimestap] =
    useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<"raw" | "parsed">("parsed");
  const [textFilter, setTextFilter] = useState<string>("");
  const [userFilter, setUserFilter] = useState<UserFilterTypes>(
    UserFilterTypes.ALL
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: "onTouched",
  });

  const { data: callInfoData, loading } = useQuery(GetCallInfoDocument, {
    variables: { meetingId: selectedMeetingId },
    skip: !selectedMeetingId || !isValid,
    onError: handleApolloError,
  });

  const grafanaUrl = useMemo(() => {
    if (!callInfoData) return "";
    const { startAt, endAt } = callInfoData.meeting.interval;
    const TIME_MARGIN = 1000 * 60 * 5; // We add a margin to the start and end time for the graph
    const baseUrl =
      "https://grafana-prod.ameelio.org/d/b9041086-2f9b-4d0b-bcb7-451b27cdc51f/call-metrics";
    return `${baseUrl}?orgId=1&var-callId=${callInfoData.meeting.id}&from=${
      startAt - TIME_MARGIN
    }&to=${endAt + TIME_MARGIN}`;
  }, [callInfoData]);

  useEffect(() => {
    if (!callInfoData) return;
    const url = callInfoData?.meeting.call?.callLogUrl;
    if (url) {
      setLogData(null);
      setParsedLogData([]);
      readFile(url).then((logs) => {
        setLogData(logs);
        setParsedLogData(parseLog(logs));
      });
    } else {
      snackbarContext.alert("error", "No logs found for this meeting");
    }
  }, [callInfoData, snackbarContext]);

  const callLogUrl = callInfoData?.meeting.call?.callLogUrl;

  useEffect(() => {
    const userFilteredData =
      userFilter === UserFilterTypes.ALL
        ? parsedLogData
        : parsedLogData.filter((entry) => entry.userType === userFilter);
    if (!textFilter) {
      setFilteredLogData(userFilteredData);
      return;
    }
    const filteredData = userFilteredData.filter((entry) =>
      entry.eventName?.toLowerCase().includes(textFilter.toLowerCase())
    );
    setFilteredLogData(filteredData);
  }, [parsedLogData, textFilter, userFilter]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        setLogData(text);
        setParsedLogData(parseLog(text));
      };
      reader.readAsText(file);
    }
  };

  return (
    <Screen title="Debug calls" maxWidth="xl">
      <Stack spacing={3}>
        <TextInput
          control={control}
          name="meetingId"
          label="Meeting Id"
          rules={mergeRules(
            isRequired("Please provide a valid meeting id"),
            isValidUUID()
          )}
        />
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={2}
        >
          <Button
            variant="contained"
            disabled={isSubmitting || loading}
            onClick={handleSubmit(({ meetingId: mId }) =>
              setSelectedMeetingId(mId)
            )}
          >
            View Logs
          </Button>
          {callLogUrl && (
            <>
              <Button
                variant="outlined"
                onClick={() => downloadFile(callLogUrl)}
              >
                Download Logs
              </Button>
              {grafanaUrl && (
                <MUIButton
                  variant="outlined"
                  component="a"
                  href={grafanaUrl}
                  rel="noopener"
                  target="_blank"
                >
                  Open in Grafana
                </MUIButton>
              )}
            </>
          )}
          <Button variant="outlined" component="label">
            Load Log File
            <input
              type="file"
              accept=".txt,.log"
              hidden
              onChange={handleFileChange}
            />
          </Button>
        </Stack>
      </Stack>
      {logData ? (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            aria-label="Debug tabs"
            sx={{ my: 2 }}
          >
            <Tab label="Parsed log" value="parsed" />
            <Tab label="Raw log" value="raw" />
          </Tabs>
          {activeTab === "raw" && (
            <Box mt={4}>
              <Box
                component="pre"
                p={2}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: 1,
                  overflow: "auto",
                  maxHeight: 550,
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                {logData}
              </Box>
            </Box>
          )}
          {activeTab === "parsed" && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextInputBase
                      size="small"
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      value={textFilter}
                      label="Event name"
                      onChange={(e) => {
                        setTextFilter(e.target.value);
                      }}
                      addClearIcon
                      onClear={() => {
                        setTextFilter("");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInputBase
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setUserFilter(e.target.value as UserFilterTypes);
                      }}
                      value={userFilter}
                      items={Object.values(UserFilterTypes).map((r) => ({
                        name: r,
                        value: r,
                      }))}
                      label="User type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CheckboxInputBase
                      onChange={() =>
                        setShowRelativeTimestap(!showRelativeTimestamp)
                      }
                      value={showRelativeTimestamp}
                      label="Show relative timestamp"
                      error={false}
                      helperText=""
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4} maxHeight={550} overflow="auto">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {!showRelativeTimestamp && (
                          <TableCell>Timestamp</TableCell>
                        )}
                        {showRelativeTimestamp && (
                          <TableCell>Relative timestamp</TableCell>
                        )}
                        <TableCell>User type</TableCell>
                        <TableCell>Socket ID</TableCell>
                        <TableCell>Event Name</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLogData.map((entry, index) => (
                        <TableRow
                          key={sanitizeIndexKey(
                            entry.timestamp + index + entry.message
                          )}
                        >
                          {!showRelativeTimestamp && (
                            <TableCell sx={{ color: inferRowColor(entry) }}>
                              {entry.timestamp}
                            </TableCell>
                          )}
                          {showRelativeTimestamp && (
                            <TableCell sx={{ color: inferRowColor(entry) }}>
                              {entry.relativeTimestamp}
                            </TableCell>
                          )}
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.userType}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.socketId}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.eventName}
                          </TableCell>
                          <TableCell sx={{ color: inferRowColor(entry) }}>
                            {entry.message}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </>
      ) : null}
    </Screen>
  );
}
