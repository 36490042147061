import { t } from "i18next";
import { MeetingType, SecurityFeature } from "../api/graphql";

export const isMonitored = (features: SecurityFeature[]) => {
  return features.includes(SecurityFeature.Monitoring);
};

export const isConfidential = (features: SecurityFeature[]) => {
  const monitored = features.includes(SecurityFeature.Monitoring);
  const recorded = features.includes(SecurityFeature.Recording);
  return !(monitored || recorded);
};

export function securityDisclaimer(
  meetingType: MeetingType,
  features: SecurityFeature[]
): string {
  const monitored = features.includes(SecurityFeature.Monitoring);
  const recorded = features.includes(SecurityFeature.Recording);
  const pictures = features.includes(SecurityFeature.Pictures);
  const logged = features.includes(SecurityFeature.Logged);
  switch (meetingType) {
    case MeetingType.InPersonVisit:
      if (monitored) {
        return t(
          "By requesting and attending this visit, I consent to the monitoring of my conversation by the affiliated correctional facility. I agree to abide by the facility's visitor policies and understand that failure to do so may result in being denied visitation."
        );
      }
      return "";
    case MeetingType.VoiceCall:
      if (!monitored && !recorded) {
        if (logged) {
          return t(
            "By participating in this call, I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
          );
        }
        return "";
      }
      if (!monitored && recorded) {
        return t(
          "By participating in this call, I consent to the potential recording of audio by the affiliated correctional facility. I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
        );
      }
      if (monitored && !recorded) {
        return t(
          "By participating in this call, I consent to the potential monitoring of audio by the affiliated correctional facility. I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
        );
      }
      if (monitored && recorded) {
        return t(
          "By participating in this call, I consent to the potential monitoring and recording of audio by the affiliated correctional facility. I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
        );
      }
      break;
    default:
      if (!monitored && !recorded && !pictures) {
        if (logged) {
          return t(
            "By participating in this call, I agree to abide by the facility's stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities."
          );
        }
        return "";
      }
      if (!monitored && !recorded && pictures) {
        return t(
          "This call cannot be monitored or recorded, but low-quality pictures are taken at random times for review in accordance with facility policy."
        );
      }
      if (!monitored && recorded) {
        // We're deliberately ignoring the value of pictures here. Pictures are not relevant if the call is recorded.
        return t(
          "By participating in this call, I consent to the potential recording of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
        );
      }
      if (monitored && !recorded && !pictures) {
        return t(
          "By participating in this call, I consent to the potential monitoring of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
        );
      }
      if (monitored && !recorded && pictures) {
        return t(
          "By participating in this call, I consent to the potential monitoring of my video, audio, and in-call chat messages by the affiliated correctional facility. Low-quality pictures will also be taken at random times for review in accordance with facility policy. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
        );
      }
      if (monitored && recorded) {
        // We're deliberately ignoring the value of pictures here. Pictures are not relevant if the call is recorded.
        return t(
          "By participating in this call, I consent to the potential monitoring and recording of my video, audio, and in-call chat messages by the affiliated correctional facility. I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption or termination of my call."
        );
      }
  }
  return securityFeaturesDescription(meetingType, features);
}

export const securityFeaturesDescription = (
  meetingType: MeetingType,
  features: SecurityFeature[]
) => {
  const monitored = features.includes(SecurityFeature.Monitoring);
  const recorded = features.includes(SecurityFeature.Recording);
  const pictures = features.includes(SecurityFeature.Pictures);
  const logged = features.includes(SecurityFeature.Logged);

  switch (meetingType) {
    case MeetingType.InPersonVisit:
      if (monitored) {
        return t("This visit is monitored.");
      }
      if (!logged) {
        return t("This visit is hidden.");
      }
      return t("This visit is not monitored.");
    case MeetingType.VoiceCall:
      if (!logged) {
        return t("This call is hidden.");
      }
      if (!monitored && !recorded) {
        return t("This call cannot be monitored or recorded.");
      }
      if (!monitored && recorded) {
        return t(
          "This call cannot be monitored, but it will be recorded for review in accordance with facility policy."
        );
      }
      if (monitored && !recorded) {
        return t("This call can be monitored.");
      }
      if (monitored && recorded) {
        return t(
          "This call can be monitored and recorded for review in accordance with facility policy."
        );
      }
      break;
    default:
      if (!logged) {
        return t("This call is hidden.");
      }
      if (!monitored && !recorded && !pictures) {
        return t("This call cannot be monitored or recorded.");
      }
      if (!monitored && !recorded && pictures) {
        return t(
          "This call cannot be monitored or recorded, but low-quality pictures are taken at random times for review in accordance with facility policy."
        );
      }
      if (!monitored && recorded) {
        // Deliberately ignoring the value of pictures here. Pictures are not relevant if the call is recorded.
        return t(
          "This call cannot be monitored, but it will be recorded for review in accordance with facility policy."
        );
      }
      if (monitored && !recorded && !pictures) {
        return t("This call can be monitored.");
      }
      if (monitored && !recorded && pictures) {
        return t(
          "This call can be monitored, and low-quality pictures will be taken at random times for review in accordance with facility policy."
        );
      }
      if (monitored && recorded) {
        // Deliberately ignoring the value of pictures here. Pictures are not relevant if the call is recorded
        return t(
          "This call can be monitored and recorded for review in accordance with facility policy."
        );
      }
  }
  return "";
};
